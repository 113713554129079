import { trackEvent } from 'monitoring/events'
import { getStringParam } from 'utils/router/routerUtils'

import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useSigninEffect = (
  canRedirect: boolean,
  disableSigninCallbackUrl?: boolean
) => {
  const session = useSession()
  const router = useRouter()

  /**
   * The signIn function from next-auth/react is only avaiable client-side. The nextjs middleware will initially
   * redirect from auth protected pages to this signin page if the user is unauthorized, which will
   * then call the signIn function to further redirect to Cognito. From the middleware, next-auth stores callbackUrl
   * from the original path into the router query object, which we then take and use in the signIn function call,
   * ensuring that the user will navigate back to the original path once they complete the authorization process.
   */
  useEffect(() => {
    if (!canRedirect) return

    // Redirect to sign in flow if user is not logged in yet or missing required session data properties
    if (
      session.status === 'unauthenticated' ||
      (session.status === 'authenticated' && !session.data.whoAmI)
    ) {
      const callbackUrl = disableSigninCallbackUrl
        ? undefined
        : getStringParam(router.query.callbackUrl)

      if (session.status === 'authenticated') {
        trackEvent('signed-in-user-missing-whoami', { callbackUrl })
      }

      trackEvent('redirect-to-signin-page', { callbackUrl })
      signIn('cognito', { callbackUrl })
    }
  }, [session.status, canRedirect])
}
