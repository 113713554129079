/**
 * A query parameter from router.query can technically be a
 * string or an array of strings. We typically only assign
 * a single string however. For exhaustive type checking however,
 * we have to account for both cases. This util will always return
 * a string, including an empty string if the param is undefined.
 */
export function getStringParam(param: string | string[] = ''): string {
  if (Array.isArray(param)) {
    return param[0]
  }

  return param
}
